.category-deletemodal-show {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-deletemodal-hide {
  display: none;
}

.category-deletemodal-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.05);
}

.category-deletemodal-container {
  background-color: white;
  width: 30rem;
  min-height: 15rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem;
  border-radius: 10px;
  align-items: center;
  position: relative;
}

.category-deletemodal-container h2 {
  color: black;
  padding: 20px;
  font-size: 25px;
}

.category-deletemodal-btn-container {
  display: flex;
  gap: 2rem;
  padding-bottom: 20px;
}

.category-deletemodal-btn-container button {
  padding: 10px 15px;
  outline: none;
  border: none;
  border-radius: 15px;
  font-size: 15px;
  cursor: pointer;
  color: white;
}

.category-deletemodal-btn-cancel {
  background-color: green;
}

.category-deletemodal-btn-delete {
  background-color: rgb(188, 17, 17);
}