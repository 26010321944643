@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  /* color variable */
  --app-blue-color: #0080ff;
  --app-blue-lowopacity: #eff7ff;
  --app-text-graycolor: #77838f;
  --app-text-blueshade: #393649;
  --app-backgroundcolor-gray: #ecedef;
}