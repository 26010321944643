.inspection-reject-modal {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inspection-reject-modal-container {
  background-color: white;
  width: 35rem;
  height: 25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1000;
  padding: 1rem 3rem;
  border-radius: 10px;
  align-items: center;
}

.hidden {
  display: none;
}

.show {
  display: flex;
}

.inspection-reject-modal-container>span {
  margin-left: auto;
}

.inspection-reject-modal-container>span:hover {
  cursor: pointer;
}

.textArea-container {
  width: 80%;
  height: 60%;
}

.inspection-reject-h3 {
  font-size: 21px;
  font-weight: lighter;
}

.textArea-container textarea {
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  background-color: var(--app-backgroundcolor-gray);
  border-color: var(--app-backgroundcolor-gray);
  border: 1px solid rgb(153, 152, 152);
  border-radius: 2px;
  outline: none;
}

.textArea-container p {
  color: #a93526;
}

.inspection-reject-modal-btn-container {
  display: flex;
  gap: 2rem;
}

.inspection-reject-btn {
  padding: 8px 20px;
  border: none;
  border-radius: 12px;

  color: white;
}

.inspection-reject-btn:hover {
  cursor: pointer;
}

.inspection-reject-modal-btn-green {
  background-color: #548e53;
}

.inspection-reject-modal-btn-red {
  background-color: #a93526;
}