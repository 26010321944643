.provider-dashboardpage-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.provider-dashboard-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: hidden;
  margin-top: 1rem;
}

.provider-dashboard-content-h3 {
  color: black;
  font-size: x-large;

}

.provider-dashboardpage-tiles-container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.provider-dashboardpage-tiles {
  padding: 0px 1rem;
  background-color: white;
  border-radius: 20px;
  /* height: 11rem; */
  aspect-ratio: 1.8/0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.provider-dashboardpage-tiles h2 {
  font-size: 35px;
  margin: 15px 0px 0px 0px;
}

.provider-dashboardpage-tiles h1 {
  margin: 0px 0px 15px 0px;
  font-size: 20px;
}

.provider-dashboard-tile-text {
  color: #919096;
}

/* table */
.provider-dashboardpage-table-container {
  margin-top: 10px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.provider-dashboardpage-table-wrapper {
  height: 45vh;
  overflow-y: scroll;
}

.provider-dashboardpage-tables {
  background-color: white;
  border-radius: 15px;
  padding: 1rem 20px;
  column-span: 2;
}

.provider-dashboardpage-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.provider-dashboardpage-table {
  width: 100%;
  padding: 1.5rem 0px;
  border-collapse: collapse;
  margin-top: 15px;
}

.provider-dashboardpage-table thead th {
  text-align: start;
  color: gray;
}

.provider-dashboardpage-table thead tr {
  border-bottom: 1px solid rgba(128, 128, 128, 0.421);
}

.provider-dashboardpage-table th {
  padding: 10px 0px;
  background-color: white;
}

.provider-dashboardpage-table td {
  padding-top: 15px;
  font-weight: lighter;
}

/* colors  */
.provider-dashboardpage-textcolor {
  color: black;
}

/* media query */

@media (min-width: 2000px) {
  .provider-dashboardpage-tiles h1 {
    font-size: 35px;
    font-weight: 150px;
    margin-left: 20px;
    padding-bottom: 10px;
  }

  .provider-dashboardpage-tiles-container {
    gap: 3rem;
  }

  .provider-dashboardpage-table-wrapper {
    height: 63vh;
    overflow-y: scroll;
  }

  .provider-dashboardpage-tiles h2 {
    font-size: 70px;
    padding-left: 30px;
  }

  .provider-dashboardpage-table-container {
    font-size: 25px;
    gap: 2rem;
  }

  .provider-dashboardpage-table td {
    font-size: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-weight: lighter;
  }

  .provider-dashboardpage-tables {
    padding: 2rem;
  }

  .provider-dashboardpage-table {
    width: 100%;
    padding: 2rem;
    border-collapse: collapse;
    margin-top: 15px;
  }

  .provider-dashboard-content-h3 {
    font-size: 35px;
  }

  .provider-dashboardpage-tiles {
    padding: 0px 1rem;
  }

  .provider-dashboardpage-table-header-h3 {
    font-size: 35px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}