.poster-editmodal-show {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .poster-editmodal-hide {
    display: none;
  }
  
  .poster-editmodal-wrapper {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .poster-editmodal-container {
    background-color: white;
    width: 60rem;
    min-height: 30rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    padding: 1rem 2rem;
    border-radius: 10px;
    align-items: center;
    position: relative;
  }
  .poster-editmodal-container > span {
    margin-left: auto;
    cursor: pointer;
  }
