.appbar-container {
  display: flex;
}

.appbar-serarch-container {
  width: 100%;
  display: flex;
  gap: 8px;
  padding: 0.5rem;
  align-items: center;
  width: 65%;
  border-radius: 25px;
  border: 0.5px solid rgba(128, 128, 128, 0.361);
  overflow: hidden;
  background-color: white;
}

.search-inputbar {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0px 5px 0px 5px;
  font-size: 15px;
}

.profile-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.searchbar-magnifyglass {
  padding-left: 20px;
}

.appbar-bellicon {
  background-color: rgba(119, 142, 196, 0.2);
  padding: 9px;
  border-radius: 10px;
  color: rgb(74, 113, 210);
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: gray;
  border-radius: 8px;
}

.profile-info h1 {
  font-size: medium;
  font-weight: 400;
}

.profile-info p {
  color: gray;
}

.appbar-notification-icon-container {
  position: relative;
  cursor: pointer;
}

.appbar-notification-count {
  position: absolute;
  top: 0;
  right: -10px;
  background-color: blue;
  color: white;
  border-radius: 50%;
  padding: 4px 6px;
  font-size: 0.5rem;
}

/* Centered notification popup */
.appbar-notification-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 70vh;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 10;
  overflow-y: auto;
  /* Allows scrolling if content exceeds height */
  border-radius: 8px;
  /* Optional: rounded corners */
}

/* Popup title styling */

.appbar-popup-close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
}

/* Popup title */
.appbar-notification-popup-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Popup list */
.appbar-notification-popup-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Popup list items */
.appbar-notification-popup-item {
  margin: 10px 0;
  font-size: 1rem;
  display: flex;
  padding: 10px;
  align-items: flex-start;
}

.appbar-request-number {
  font-weight: bold;
  margin-right: 8px;
}

/* media query */

@media (min-width: 2000px) {
  .appbar-container {
    margin-bottom: 15px;
  }

  .search-inputbar {
    padding: 20px 5px;
    font-size: 25px;
  }

  .searchbar-magnifyglass {
    width: 30px;
    height: 30px;
  }

  .appbar-serarch-container {
    border-radius: 35px;
  }

  .appbar-bellicon {
    height: 45px;
    width: 45px;
  }

  .avatar {
    width: 55px;
    height: 55px;
  }

  .profile-info h1 {
    font-size: x-large;
  }
}