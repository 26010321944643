* {
  padding: 10px;
  margin: 0px;
  box-sizing: border-box;
}

.inbound-container {
  width: 100%;
  height: 85vh;
  padding: 10px;

}
.loading-spinner {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  color: #888;
}

.inbound-table-container {
  background-color: white;
  margin-top: 2%;
  margin-left: 0;
  /* display: flex;
   align-items: center;
   justify-content: center; */
  width: 100%;
  /* height: 500px; */
  border-radius: 15px;
  box-shadow: 10px;
  padding: 1.5rem;
  height: 80vh;
  overflow-y: scroll;


}

.inbound-table-heading {
  font-size: 30px;
}

.inbound-details {
  border-collapse: collapse;
  width: 100%;
  /* padding: 20px; */

}

thead,
th {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;

  /* white-space: wrap;
    width: 30px; */

}



.inbound-img img {
  width: 50px;
  height: 50px;
  background-color: rgb(202, 245, 245);
  border-radius: 5px;
  margin: 3px;
  /* padding-right: 3px; */

}

.inbound-profile-container {
  display: flex;
}

table td {
  font-size: small;
  color: black;
  font-weight: lighter;
}

table th {
  color: gray;
  position: sticky;
  top: 0;
  font-weight: lighter;

}

#inbound-profile-name {
  border-bottom: none;
  overflow-wrap: break-word;

  margin-top: 11px;
}

.inbound-heading {
  margin-top: 2%;
  margin-left: 0;
  margin-bottom: 1rem;
  font-size: 20px;
  color: rgba(17, 151, 224, 0.951);
}

.inbound-address {
  overflow-wrap: break-word;
  width: 70px;
}

.inbound-icons {
  justify-content: space-between;
  display: flex;
  padding: 5px;
  margin-right: 30px;
}



/* Dark overlay background */
.inbound-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup container styling */
.inbound-date-picker-popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Close button styling */
.inbound-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
}

/* Schedule button styling */
.inbound-schedule-button {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: rgba(13, 97, 142, 0.951);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.inbound-schedule-button:hover {
  background-color: rgb(5, 5, 94);
}

.react-datepicker__header {
  background-color: #ffffff !important;
  /* Ensure header background is white */
  border-bottom: none;
}

.react-datepicker__time-header {
  color: #007bff;
  /* Blue color for time heading */
  font-weight: bold;
  margin-bottom: 20px;
  /* 20px margin below time heading */
}

.inbound-selected-date-input {
  width: 50%;
  padding: 5px;
  margin: 20px;
  border-radius: 5px;
  border-color: #007bff;
  font-size: medium;
  text-align: center;

}

h2 {
  color: rgba(26, 120, 170, 0.951);
}

.react-datepicker {
  padding: 8px;
  width: 80%;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 99px;
  margin-top: 11px;

}

.react-datepicker__navigation-icon--previous {
  right: -7px;
  margin-top: 14px;
}

.inbound-icon-calender {
  font-size: 20px;
  color: #e07a06;
}

.inbound-icon-search {
  font-size: 20px;
  color: #acc9e0;
}

.inbound-icon-trash {
  font-size: 20px;
  color: #ca0202;
}

@media (min-width: 2000px) {
  .inbound-heading {
    font-size: 40px;
    padding-top: 2rem;
    font-weight: 200px;

  }

  .inbound-table-heading {
    font-size: 35px;
    padding: 20px;
    margin-left: 20px;
  }

  .inbound-table-container {
    font-size: 35px;
  }

  table td {
    font-size: 25px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--app-text-blueshade);
  }

  table th {
    font-size: 30px;
    padding-left: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .inbound-img img {
    width: 60px;
    height: 60px;
    margin-top: 20px;
    /* padding-right: 3px; */

  }

  .inbound-details {

    margin-left: 30px;
  }

  .inbound-icons {

    margin-right: 50px;
  }

  .inbound-icon-calender {
    font-size: 35px;
  }

  .inbound-icon-search {
    font-size: 35px;

  }

  .inbound-icon-trash {
    font-size: 35px;
  }

}