.admin-course-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 10px;
}

.admin-course-content-h3 {
    color: var(--app-blue-color);
    font-size: 25px;
}

.admin-course-content-container {
    padding: 1rem 1.5rem;
    background-color: white;
    border-radius: 10px;
    color: var(--app-text-blueshade);
    overflow-y: scroll;
    height: 75vh;
}

/* Additional styles for dropdown, header, and cards */
.admin-course-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding: 10px;
}

.admin-course-select-provider {
    padding: 5px;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.admin-course-card-container {
    margin-top: 5rem;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.admin-course-card-container img {
    width: 100%;
    height: 15rem;
    object-fit: contain;
}

.admin-course-select-provider {
    padding: 5px;
    font-size: 15px;
    border-radius: 0px;
    cursor: pointer;
    color: black;
    /* Set text color */
    background-color: white;
    border: none;
    /* Set background color */
    border-bottom: 1px solid gray;
    appearance: none;
    /* Optional: hides default arrow for consistency in style */
}

/* Optional: Add hover and focus styles for better visibility */
.admin-course-select-provider:hover {
    background-color: #f0f0f0;
}

.admin-course-select-provider:focus {
    outline: 2px solid var(--app-blue-color);
    /* Highlight the dropdown when focused */
}

.admin-course-card-details {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0px 6px 0.8rem;
    align-items: center;
}

.admin-course-card-details p {
    width: 15rem;
}

.admin-course-card-actions-container {
    margin-left: auto;
    display: flex;
    gap: 5px;
}

.admin-course-card-action-btn {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: medium;
    cursor: pointer;
    border-radius: 20px;
}

.admin-course-card-action-delete {
    color: red;
}

/* Repeat updates for remaining classes */