.button {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.button.primary {
  background-color: #2196F3;
  color: #ffffff;
}

.button:hover {
  background-color: #f0f0f0;
}

.button.primary:hover {
  background-color: #2196F3;
}