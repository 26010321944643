.users-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 4fr)); /* Dynamic grid based on screen size */
    gap: 20px;
    padding: 0px;
  }
  .courses-container {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(120px, 4fr)); /* Dynamic grid based on screen size */
    gap: 0px;
    padding: 0px;
  }
  .users-contain {
    display: grid;
    width: 100%;
    gap: 10px;
    padding: 0px;
  }

  .user-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
    justify-content: space-between;

  }
     .use-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
    justify-content: space-between;

  }
  .course-card {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    /* border-radius: 8px; */
    padding:  0 5px;
    text-align: center;
    transition: transform 0.3s ease;
    justify-content: space-between;

  }
  .course-card button{
    border-radius: 30px;
    padding: 5px;
    width: 100px;
    font-size: smaller;
    border: 2px solid #387CB8;
    background: transparent;
    color: #387CB8;
  }
  .course-card button:hover{
    color: #fff;
  }
  .course-card p{
    margin: 10px;
  }
  .add-image-button{
    margin: 15px;
    width: 150px;
  }
  .see-courses-button{
    align-self: flex-end;
    width: 150px;
    margin: 15px;
  }
  .user-card:hover {
    transform: translateY(-5px); /* Adds a hover effect */
  }
  .course-card:hover{
    transform: translateY(-1px); /* Adds a hover effect */

  }
  .user-logo {
    width: auto;
    height: 80px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  .use-logo {
    width: auto;
    height: 50px;
    object-fit: cover;
    margin: 15px 15px 10px 10px;
  }
  .user-name {
    font-size: small;
    font-weight: bold;
    margin-top: 10px;
  }
  .users-name {
    text-align: center;
    font-size: large;
    font-weight: bold;
    margin-top: 10px;
    width: 100%;
  }
  .no-of-course{
    margin-left: 15px;
  }

  .vbutton-container {
    display: flex;
    justify-content: center;
    gap: 0px;
}
.vbutton-container button {
    padding: 5px 5px;
    background-color: #387CB8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edit-v-button, .delete-v-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    font-size: 10px;
    margin: 5px;
}
.form-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:  0 5%;
}

.form-header button{
  background-color: transparent;
  border: none;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust based on your layout */
}

.loading-dots span {
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background: #f25c2a; /* Change to your desired color */
  animation: loading 1s infinite alternate;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
.loader-container{
  align-items: center;
  justify-content: center;
  margin-top: 25%;
}