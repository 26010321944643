.poster-addmodal-show {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .poster-addmodal-hide {
    display: none;
  }
  
  .poster-addmodal-wrapper {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .poster-addmodal-container {
    background-color: white;
    width: 60rem;
    min-height: 30rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    padding: 1rem 2rem;
    border-radius: 10px;
    align-items: center;
    position: relative;
  }
  .poster-addmodal-container > span {
    margin-left: auto;
    cursor: pointer;
  }

  .campaign-addmodal-container>span:hover {
    cursor: pointer;
  }
  
  .campaign-addmodal-container h2 {
    color: black;
  }
  
  /* form */
  .campaign-addmodal-form {
    display: grid;
    gap: 20px;
    width: 100%;
    grid-template-rows: repeat(1, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
    color: var(--app-text-blueshade);
    margin-top: 2rem;
  }
  
  .campaign-addmodal-form-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .campaign-addmodal-form-fieldcontainer {
    width: 100%;
  }
  
  .campaign-addmodal-form-fieldcontainer p {
    padding: 0px 0px 5px 5px;
  }
  
  .campaign-addmodal-form-datecontainer {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  
  .campaign-addmodal-form-input {
    padding: 10px 15px;
    border-radius: 15px;
    border: 1px solid #d9dadb;
    outline: none;
    background-color: var(--app-backgroundcolor-gray);
    color: var(--app-text-blueshade);
    width: 100%;
  }
  
  .campaign-addmodal-form-right {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
  }
  
  .campaign-addmodal-form-imageuploder-container {
    display: inline-flex;
    cursor: pointer;
    text-align: center;
    width: 100%;
    background-color: var(--app-backgroundcolor-gray);
  }
  
  .campaign-addmodal-form-imageuploder-container img {
    width: 100%;
    height: 13rem;
    object-fit: contain;
  }
  
  .campaign-addmodal-form-publishbtn {
    background-color: var(--app-blue-color);
    padding: 10px 15px;
    color: white;
    border: none;
    outline: none;
    width: 10rem;
    font-size: 15px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .campaign-addmodal-form-publishbtn:hover {
    background-color: #0466c9;
  }
  
  @media(min-width:2000px) {
    .campaign-addmodal-container {
      width: 68rem;
      height: 35rem;
    }
  
    .campaign-addmodal-container h2 {
      font-size: 35px;
    }
  
    .campaign-addmodal-form-fieldcontainer p,
    .campaign-addmodal-form-publishbtn {
      font-size: 25px;
    }
  
  
  }