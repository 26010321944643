.activity-editmodal-container{
    background-color: white;
    width: 30rem;
    /* min-height: 30rem; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    padding: 1rem 2rem;
    border-radius: 10px;
    align-items: center;
    position: relative;
}
.activity-editmodal-container > span {
    margin-left: auto;
    cursor: pointer;
  }

