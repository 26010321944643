.amenities-addmodal-container {
    background-color: white;
    width: 30rem;
    min-height: 30rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    padding: 1rem 2rem;
    border-radius: 10px;
    align-items: center;
    position: relative;
}

.amenities-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.amenity-item {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 15px;
}

.custom-checkbox {
    width: 25px;
    height: 25px;
    appearance: none;
    background-color: white;
    border: 2px solid black;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox:checked::after {
    content: "✔";
    font-size: 18px;
    color: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.amenity-item p {
    margin-left: 10px;
    font-size: 1rem;
    color: black;
}

.add-amenities-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}