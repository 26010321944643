/* editmodal styles */
.editmodal {
  display: flex;
  /* Use flex to center */
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
}

.editmodal-container {
  background-color: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 5px;
}

.editmodal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  /* Make sure it is responsive */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* Shadow for depth */
}

.editmodal h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 600;
}

.editmodal form div {
  margin-bottom: 15px;
}

.editmodal label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.editmodal input {
  width: 100%;
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 12px;
  color: #727272;
  margin-bottom: 10px;
  border: 2px solid #9f9e9e67;
  border-radius: 25px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  outline: none;
  transition: border-color 0.3s;
}

.editmodal input:focus {
  border: 1px solid #2196F3;
  box-shadow: 0 0 5px rgba(226, 105, 54, 0.5);
}

.editmodal input:hover {
  border: 1px solid #2196F3;

}

.editmodal input::-webkit-file-upload-button {
  background: transparent;
  border: 2px solid #ccc;
  border-radius: 25px;
  width: 150px;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #9f9e9e91;
}

.editmodal label {
  color: #387cb8c7;

}

.editmodal button {
  padding: 10px 15px;
  background-color: #387CB8;
  border: none;
  border-radius: 20px;
  color: white;
  margin-bottom: 10px;
  width: 180px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.editmodal button:hover {
  background-color: #0056b3;
}

.editmodal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}