.categorypage-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 10px;
}

.category-content-h3 {
  color: var(--app-blue-color);
  font-size: 25px;
}


.categorypage-content-container {
  padding: 1rem 1.5rem;
  background-color: white;
  border-radius: 10px;
  color: var(--app-text-blueshade);
  overflow-y: scroll;
  height: 75vh;
}

.categorypage-content-header {
  border-bottom: 1px solid gray;
  padding: 10px;
}

.categorypage-content-header1 {
  padding-top: 15px;
  float: right;

}

.categorypage-content-header1 button {
  padding: 10px 15px;
  outline: none;
  border: none;
  background-color: var(--app-blue-color);
  color: white;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;

}

.categorypage-content-header button:hover {
  background-color: #0466c9;
}

.categorypage-card-container {
  margin-top: 5rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.categorypage-card {
  display: flex;
  flex-direction: column;
  width: 15rem;
  /* max-height: 15rem; */
}

.categorypage-card img {
  width: 100%;
  height: 10rem;
  object-fit: contain;
}

.categorypage-card-details {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 0px 6px 0.8rem;
  align-items: center;
}

.categorypage-card-details p {
  width: 8rem;
}

.categorypage-card-actions-container {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.categorypage-card-action-btn {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-size: medium;
  cursor: pointer;
  border-radius: 20px;
}

.categorypage-card-action-edit {
  color: var(--app-blue-color);
}

.categorypage-card-action-delete {
  color: red;
}

.categorypage-card-action-btn:hover {
  background-color: var(--app-backgroundcolor-gray);
}

.tab-button {
  background-color: transparent;
  border: none;
  color: black;
  /* Default color for non-active tabs */
  font-size: 15px;
  /* Default size for h3 */
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-right: 30px;
}

.tab-button.active {
  color: #2196F3;
  /* Color for active tab */
}






@media (min-width: 2000px) {

  .category-content-h3 {
    padding-top: 1rem;
    font-size: 45px;
    padding-bottom: 10px;
  }

  .categorypage-content-header1 button {

    font-size: 25px;

  }

  .categorypage-content-header {
    font-size: 35px;

  }

  .categorypage-card-details p {
    font-size: 25px;
  }

  .categorypage-card {
    width: 30rem;
  }

  .categorypage-card img {
    height: 21rem;
  }

  .categorypage-card-action-btn {
    font-size: 35px;
  }

  .tab-button {
    font-size: 35px;
    /* Larger size on large screens */
  }


}