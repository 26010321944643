@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.asign-form-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.asignup-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 350px;
  text-align: center;
}


.aform-sign-in {
  display: flex;
  flex-direction: column;
}

.aform-sign-in h2 {
  margin-bottom: 1.5rem;
  font-size: 24px;
  color: #387CB8;
}

.aform-sign-in input {
  margin-bottom: 1rem;
  padding: 0.8rem 2.4em;
  font-size: .8em;
  border: 1px solid #ccc;
  border-radius: 30px;
  width: 100%;
  box-sizing: border-box;
}

.aform-sign-in input:hover {
  border-color: #2196F3;
  outline: none;
}

.aform-sign-in input:focus {
  border-color: #2196F3;
  box-shadow: 0 0 5px rgba(54, 154, 226, 0.5);
  outline: none;
}

.aform-sign-in button {
  padding: 0.8rem;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aform-sign-in button:hover {
  background-color: #207dc8;
  ;
}

.error-message {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 1rem;
}

.success-message {
  color: #387CB8;
  font-size: 14px;
  margin-top: 1rem;
}

.alogo-container img {
  cursor: pointer;
  width: 200px;
  max-width: 200px;
  margin-bottom: 1rem;
}

.loader {
  text-align: center;
  margin-top: 20px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #387CB8;
  border-radius: 5px;
  transition: width 0.1s;
}