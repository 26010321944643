/* Popup window styling */
.popup-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  min-height: 500px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
  z-index: 1000;
  padding: 20px 30px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.popup-window h3 {
  margin-top: 0;
  text-align: center;
  font-size: 1.5em;
  color: var(--app-blue-color);
}

.popup-window ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.popup-window li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.popup-window li:hover {
  background-color: #f0f0f0;
}

/* Close button styling */
.request-popup-close-button {
  background: none;
  border: none;
  color: #999;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-left: auto;
}

.close-button:hover {
  color: #333;
}

.request-popup-wrapper {
  margin-top: 2rem;
  height: 50vh;
  overflow-y: scroll;
}

.request-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--app-backgroundcolor-gray);
  padding-bottom: 1rem;
}

.request-popup-header h4 {
  max-width: 30rem;
  margin: 0px;
}

.request-popup-header-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.request-popup-header-icon-globe-container {
  background-color: var(--app-blue-color);
  padding: 7px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: var(--app-text-blueshade);
  font-size: 15px;
}

.request-popup-location {
  display: flex;
  gap: 10px;
  align-items: center;
}

.request-popup-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.request-popup-content-bio {
  margin-top: 3rem;
}

.request-popup-download-button {
  padding: 10px 20px;
  background-color: var(--app-blue-color);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
  width: fit-content;
}

.pending-form-contacts-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.tab-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  border-bottom: 1px solid #9999992e;
}

.tab-buttons button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tab-buttons button.active {
  background-color: transparent;
  color: #387cb8;
  border-bottom: 2px solid #387cb8;
}

/* confirmationPopup.css */
.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-window {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 300px;
}

.confirmation-buttons {
  margin-top: 20px;
}

.confirmation-buttons button {
  margin: 0 10px;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

.confirmation-buttons button:nth-child(1) {
  background-color: #387cb8;
}

.confirmation-buttons button:nth-child(2) {
  background-color: red;
}

/* rejectionReasonPopup.css */
.rejection-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rejection-window {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 400px;
}

.rejection-buttons {
  margin-top: 20px;
}

.rejection-buttons button {
  margin: 0 10px;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

.rejection-buttons button:nth-child(1) {
  background-color: #387cb8;
}

.rejection-buttons button:nth-child(2) {
  background-color: red;
}

.rejection-window textarea {
  border: 1px solid #387cb8;
  width: 90%;
  border-radius: 5px;
  outline: none;
}

.rejection-window textarea:active {
  border: 1px solid #387cb8;
}

.request-count {
  width: 0px;
  /* Fixed width for the circle */
  height: 0px;
  /* Fixed height for the circle */
  margin-left: 5px;
  border-radius: 50%;
  /* Makes the div a circle */
  background-color: #387cb8;
  /* Background color of the circle */
  color: white;
  /* Text color */
  display: flex;
  /* Flexbox to center the text */
  align-items: center;
  /* Vertically center the text */
  justify-content: center;
  /* Horizontally center the text */
  text-align: center;
  /* Center text for multi-line */
  overflow: hidden;
  /* Ensures text doesn't overflow */
  padding: 10px;
  /* Optional padding */
  font-size: 12px;
  /* Adjust font size */
  white-space: nowrap;
  /* Prevent text wrapping */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional shadow for effect */
}

/* media query */

@media (min-width: 2000px) {
  .popup-window {
    width: 1400px;
    min-height: 600px;
    padding: 30px 35px;
  }

  .popup-window h3 {
    font-size: 40px;
  }

  .request-popup-header h4 {
    max-width: 50rem;
    font-size: 40px;
  }

  .request-popup-header-icon-globe-container {
    padding: 10px;
    border-radius: 50%;
  }

  .request-popup-header-icon-instagram {
    width: 4rem;
    height: 4rem;
  }

  .request-popup-header-icon-globe {
    width: 3.5rem;
    height: 3.5rem;
  }

  .request-popup-content {
    font-size: 25px;
    gap: 3rem;
  }

  .pending-form {
    gap: 3rem;
  }

  .request-popup-download-button {
    padding: 20px 40px;
    font-size: 25px;
    border-radius: 30px;
  }

  .request-popup-close-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}