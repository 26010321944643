.dash-layout-container {
  display: flex;
  width: 100%;
  min-height: 100dvh;
  background-color: #fbfaff;
  position: relative;
}

.sidebar-conteiner {
  width: 250px;
  background-color: rgba(128, 128, 128, 0.05);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100dvh;
  overflow-y: scroll;
}

.dash-layout-content {
  margin-left: 250px;
  width: 100%;
  background: rgba(128, 128, 128, 0.1);
  overflow-x: scroll;
}

@media (min-width: 1440px) {
  .dash-layout-content {
    margin-left: 16.3%;
  }

  .sidebar-conteiner {
    width: 16.3%;
  }
}